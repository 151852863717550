exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-animations-tsx": () => import("./../../../src/pages/animations.tsx" /* webpackChunkName: "component---src-pages-animations-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-duck-page-tsx": () => import("./../../../src/pages/duckPage.tsx" /* webpackChunkName: "component---src-pages-duck-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oberyn-page-tsx": () => import("./../../../src/pages/oberynPage.tsx" /* webpackChunkName: "component---src-pages-oberyn-page-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-turtle-page-tsx": () => import("./../../../src/pages/turtle-page.tsx" /* webpackChunkName: "component---src-pages-turtle-page-tsx" */)
}

